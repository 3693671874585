@import '../bootstrap_overrides/_variables.scss';
@import '../mixins/_custom-mixins.scss';

//COPY WITH CAROUSEL
.pb-copy-with-carousel {
    .nav-pills {
        // Styling for the vertical tabs
        display: flex;
        flex-direction: column;
        border: none;
        max-height: 415px;
        overflow: hidden;

        .nav-link {
            color: #CBCBCB;
            font-size: 25px;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
            padding: 1rem 0;
            border: none;

            &:hover {
                color: $red;
                cursor: pointer;
            }

            &.active {
                color: $black;
                font-weight: 600;
            }
        }
    }

    &__nav {
        background: none;
        padding: none;
        position: relative;
        margin: 0 auto;
        display: block;
        width: 74px;

        &[disabled] {
            opacity: .3;
            cursor: not-allowed;
        }

        &:hover {
            background: transparent;
        }

        &:after {
            content: "";
            background: url('../../assets/images/icon_arrow_down_black.png') no-repeat center;
            background-size: 100%;
            right: auto;
            left: 50%;
            transform: translate(-50%, 0);
            width: 35px;
            height: 35px;
            top: 2px;
            position: absolute;
        }

        &#scrollUp {
            &:after {
                content: "";
                background: url('../../assets/images/icon_arrow_down_black.png') no-repeat center;
                background-size: 100%;
                right: auto;
                left: 50%;
                transform: translate(-50%, 0) rotate(180deg);
                width: 35px;
                height: 35px;
                top: 2px;
                position: absolute;
            }
        }

        &#scrollDown {
            &:after {
                content: "";
                transform: translate(-50%, 0);
            }
        }
    }

    .image-wrapper-wrapper {
        padding-right: 15px;
        @include bpr-media-breakpoint(down, md) {
            padding-right: 0;
        }
    }

    .tab-content {
        .tab-pane {
            display: none;

            &.active {
                display: block;
            }

            .row {
                .image-wrapper {
                    max-width: 445px;
                    margin-left: 3rem;

                    @include bpr-media-breakpoint(down, md) {
                        margin: 5rem auto;
                    }
                }

                a:not([href]) {
                    color: $red;
                }

                @include bpr-media-breakpoint(down, md) {
                    .slide-item {
                        text-align: center;
                    }
                }
            }
        }
    }
}
